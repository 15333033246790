import { IoHomeOutline } from '@react-icons/all-files/io5/IoHomeOutline';
import { useState } from 'react';
import { Link } from 'react-router-dom';

const Items = [
  {
    href: '/',
    label: 'home',
    icon: <IoHomeOutline className="text-5xl text-blue-500 top-50 h-50 w-50" />,
  },
  {
    href: '/service',
    label: 'service',
    icon: <IoHomeOutline className="text-5xl text-blue-500 top-50 h-50 w-50" />,
  },
  {
    href: '/contact',
    label: 'contact',
    icon: <IoHomeOutline className="text-5xl text-blue-500 top-50 h-50 w-50" />,
  },
];
export const Navigation = () => {
  const [openMenu, setOpenMenu] = useState(false);
  const menu = () => {
    setOpenMenu(!openMenu);
  };
  const items = Items.map(({ href, label, icon }) => {
    return (
      <li key={label}>
        {label === 'contact' ? (
          <Link className="flex" target="_blank" rel="noopener" to={href}>
            {label}
            {icon}
          </Link>
        ) : (
          <Link className="flex" to={href}>
            {label}
            {icon}
          </Link>
        )}
      </li>
    );
  });
  return (
    <nav>
      <ul className="flex">
        {items}
        <li className="lg:hidden">
          <button onClick={menu}></button>
        </li>
        {openMenu ? <ul className="flex right-0 flex-col">{items}</ul> : undefined}
      </ul>
    </nav>
  );
};
