const Flutter = () => {
  // if (window.location.pathname === "/web/app.html") {
  //       window.location.reload();
  //   }
  return (
    <div className="flex flex-col justify-center items-center h-screen">
      <h2>読み込み中</h2>
      <div className="w-20 h-20 rounded-full  border-4 border-blue-500 border-t-transparent animate-spin"></div>
    </div>
  );
};
export default Flutter;
