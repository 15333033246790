import { Link } from 'react-router-dom';
import { Navigation } from '../navigation';
import bgImage from 'src/static/crown-gold2.png';
function Header() {
  return (
    <header>
      <div className=" fixed top-0 flex justify-between bg-green-700 h-10 w-screen">
        <h1 className="">
          <Link to="/">
            <img loading="lazy" src={bgImage} alt="rerate-home" width={80} height={20}></img>
          </Link>
        </h1>
        <Navigation />
      </div>
    </header>
  );
}
export default Header;
