import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Footer } from 'src/component/footer';
import Header from 'src/component/header';
import { LayoutProps } from './types';
const Items = {
  home: {
    ogTitle: { content: 'home' },
    ogDiscription: { content: 'ホーム' },
    ogType: { content: 'website' },
    ogUrl: { content: 'https://rerate.app' },
    ogImage: { content: '/ogp-image/ogp-test.png' },
    ogSiteName: { content: 'Rerate' },
  },
  contact: {
    ogTitle: { content: 'contact' },
    ogDiscription: { content: 'コンタクト' },
    ogType: { content: 'article' },
    ogUrl: { content: 'https://rerate.app/contact' },
    ogImage: { content: '/ogp-image/ogp-test.png' },
    ogSiteName: { content: 'Rerate' },
  },
};
export function MainLayout({ page, children, description }: LayoutProps): JSX.Element {
  let a = Items.home;
  switch (window.location.pathname) {
    case '/':
      a = Items.home;
      break;
    case '/contact':
      a = Items.contact;
      break;
  }

  return (
    <>
      <HelmetProvider>
        <Helmet title={`Rerate ${page}`}>
          <head prefix="og: http://ogp.me/ns#" />
          <meta property="og:title" content={a.ogTitle.content} />
          <meta property="og:description" content={a.ogDiscription.content} />
          <meta property="og:type" content={a.ogType.content} />
          <meta property="og:url" content={a.ogUrl.content} />
          <meta property="og:image" content={a.ogImage.content} />
          <meta property="og:site_name" content={a.ogSiteName.content} />
          <meta property="og:locale" content="ja_JP" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta property="twitter:title" content="some title" />
          <meta property="twitter:image" content="/ogp-image/ogp-test.png" />
          <meta name="twitter:site" content="@toritonapp" />
          <meta name="twitter:description" content="toritonapp" />
        </Helmet>
      </HelmetProvider>
      {window.location.pathname === '/contact' ? null : <Header />}
      <main>{children}</main>
      {window.location.pathname === '/contact' ? null : <Footer />}
    </>
  );
}
