import React from 'react';
import { Link } from 'react-router-dom';
const Home: React.VFC = () => {
  return (
    <div className="flex justify-center items-center">
      <Link to="/web/app.html">Get Started</Link>
    </div>
  );
};
export default Home;
